export default class Symbol {
  constructor(name = Symbol.random()) {
    this.name = name;
  }

  static preload() {
    Symbol.symbols.forEach((symbol) => new Symbol(symbol));
  }

  static get symbols() {
    return [
      "animal",
      "food",
      "weather",
    ];
  }

  static random() {
    return this.symbols[Math.floor(Math.random() * this.symbols.length)];
  }
}

import Reel from "./Reel.js";
import Symbol from "./Symbol.js";

export default class Slot {
  constructor(domElement) {
    Symbol.preload();

    this.currentSymbols = [
      [Symbol.random(), Symbol.random(), Symbol.random()],
      [Symbol.random(), Symbol.random(), Symbol.random()],
      [Symbol.random(), Symbol.random(), Symbol.random()],
      [Symbol.random(), Symbol.random(), Symbol.random()],
      [Symbol.random(), Symbol.random(), Symbol.random()],
    ];

    this.nextSymbols = [
      ["animal", "food", "weather"],
      ["animal", "food", "weather"],
      ["animal", "food", "weather"],
      ["animal", "food", "weather"],
      ["animal", "food", "weather"],
    ];

    this.animalJokes = [
      "Hope you can grin and bear this.",
      "Can you believe I did this on porpoise?",
      "I could really gopher a drink.",
      "Do you find this boaring?",
      "Your tenacity gets my seal of approval.",
      "This is some poor koalaity humor.",
      "Time to alpaca it up and go home.",
      "My pony’s voice is tired from telling too many jokes. He's a little horse.",
      "You can hang these jokes on the wall-rus.",
      "Sorry these jokes are baad, I’m feeling a little sheepish.",
      "Herd the joke about the sheep? It was not goat.",
      "My cow hates loud noises, so he put my phone on moo-t.",
      "I’m running out of animal jokes. Have any lion around?",
      "Animal puns are difficult, you have to think outside the fox.",
      "Oh deer, why do you hoof to keep trying this?",
      "A bird and a cow kept falling for my tricks. They were gull-a-bull.",
      "I wanted to boost up my nest egg, so I tried robin a bank."
    ]
  
    this.foodJokes = [ 
      "Are you enjoying these cheesey jokes?",
      "I'm hoping to milk this for all its worth.",
      "Food jokes are a pizza cake.",
      "That room is full of coffee. Donut go in there.",
      "My friend only eats one breakfast. He's a cereal monogamist.",
      "It's OK to stop now. Omletting you off the hook.",
      "Is this a good way to pasta time?",
      "It's waffley hard to make up food jokes.",
      "A steak pun is a rare medium well done.",
      "Some people don’t like food jokes. They do not carrot all.",
      "We should stop this and lettuce out of our misery!",
      "It’s bean fun, but we should probably taco bout something else.",
      "I was a vegetarian for a while. It was a missed steak."
    ]
  
    this.weatherJokes = [
      "Icy what you did there.",
      "I think these terrible jokes are clouding your judgment.",
      "Hail yeah, you did it.",
      "Weather jokes are a breeze, as long as you tell them with gust-o.",
      "What is dewpoint of all this?",
      "My favorite weather muppet is Kermit the Fog.",
      "I hope we can all agree, these jokes rain supreme.",
      "Don't storm out of here, I'm just lightning the mood!",
      "There’s snow way we can keep this up much longer.",
      "I hurricane’t take any more of these.",
      "All of these weather jokes are apollen.",
      "I should have warned you up-front that this won’t blow over well."
    ]

    this.nopeJokes = [
      "What do you call a snooty criminal going downstairs? A condescending con descending.",
      "If you put a picture of yourself in a locket, you’ll be independant.",
      "I know a physicist who’s a morning person. Always up and atom.",
      "What do you call an alligator in a vest? An investigator.",
      "The furniture maker always dreamed of being promoted to department chair.",
      "I went rafting and the boat sank. It was a terrible oardeal.",
      "My friend is a grape enthusiast. She keeps raisin some good points.",
      "I’m going on vacation to refuel my motorcycle. I'll be off gassing.",
      "I’m pulling my hair out doing the budget. Too many bills toupee.",
      "This lobster keeps hoarding everything. It’s really shellfish.",
      "I like to keep a fuzzy animal in my vehicle. It's the carpet.",
      "A ghost kept writing messages on my window, in curseive.",
      "Did you know the vice president was a programming musician? He wrote Al Gore rhythms."
    ]

    this.container = domElement;

    if(this.container) {
      this.reels = Array.from(this.container.getElementsByClassName("reel")).map(
        (reelContainer, idx) =>
          new Reel(reelContainer, idx, this.currentSymbols[idx])
      );

      this.spinButton = document.getElementById("spin");
      this.spinButton.addEventListener("click", () => this.spin());

      this.spinAnswer = document.getElementById("answer");
    }
  }

  spin() {
    this.onSpinStart();

    this.currentSymbols = this.nextSymbols;
    this.nextSymbols = [
      [Symbol.random(), Symbol.random(), Symbol.random()],
      [Symbol.random(), Symbol.random(), Symbol.random()],
      [Symbol.random(), Symbol.random(), Symbol.random()],
      [Symbol.random(), Symbol.random(), Symbol.random()],
      [Symbol.random(), Symbol.random(), Symbol.random()],
    ];

    return Promise.all(
      this.reels.map((reel) => {
        reel.renderSymbols(this.nextSymbols[reel.idx]);
        return reel.spin();
      })
    ).then(() => this.onSpinEnd());
  }

  onSpinStart() {
    this.spinButton.disabled = true;
    this.spinAnswer.innerHTML = "";
    this.spinAnswer.classList.remove("animal", "food", "weather")
    this.container.classList.remove("highlight--row1", "highlight--row2", "highlight--row3")
    this.container.classList.add("pun-machine--cranked")
    
    console.log("SPIN START");
  }

  onSpinEnd() {
    this.spinButton.disabled = false;
    this.container.classList.remove("pun-machine--cranked")

    this.checkResult();
    console.log("SPIN END");
  }

  checkResult() {
    const result = this.nextSymbols
    console.log(result);

    if(result[0][0] == result[1][0] && result[0][0] == result[2][0]) {
      console.log("matched row 1");
      this.container.classList.add("highlight--row1")
      this.showJoke(result[0][0]);
    }
    else if(result[0][1] == result[1][1] && result[0][1] == result[2][1]) {
      console.log("matched row 2");
      this.container.classList.add("highlight--row2")
      this.showJoke(result[0][1]);
    }
    else if(result[0][2] == result[1][2] && result[0][2] == result[2][2]) {
      console.log("matched row 3");
      this.container.classList.add("highlight--row3")
      this.showJoke(result[0][2]);
    }
    else {
      this.showJoke("none")
    }
  }

  showJoke(type) {
    console.log(type);

    switch(type) {
    case "animal":
      this.spinAnswer.classList.add("animal");
      this.spinAnswer.innerHTML = this.animalJokes[Math.floor((Math.random()*this.animalJokes.length))]
      break;
    case "food":
      this.spinAnswer.classList.add("food");
      this.spinAnswer.innerHTML = this.foodJokes[Math.floor((Math.random()*this.foodJokes.length))]
      break;
    case "weather":
      this.spinAnswer.classList.add("weather");
      this.spinAnswer.innerHTML = this.weatherJokes[Math.floor((Math.random()*this.weatherJokes.length))]
      break;
    default:
      this.spinAnswer.innerHTML = this.nopeJokes[Math.floor((Math.random()*this.nopeJokes.length))]
      break;
    }
  }
}
